<template lang="">
    <v-card>
        <s-crud
            :config="config"
            title="Regularizaciones"
            :filter="filter"
            add
            edit
            remove
            ref="ScrudMarking"
            @save="save($event)"
            @rowSelected="rowSelected($event)"
            searchInput
        >
            <template v-slot:options>
                <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" small elevation="0" @click="duplicate">
                    <v-icon color="default" x-small>
                        fas fa-copy
                    </v-icon>
                    </v-btn>
                </template>
                <span>Duplicar</span>
                </v-tooltip>
            </template>
            <template v-slot:filter>
                <v-container>
                    <v-row>
                        <v-col>
                            <s-date
                                label="Fecha Entrada"
                                
                                v-model="filter.MrkDateEntry" />
                        </v-col>
                        <v-col>
                            <s-date
                                label="Fecha Salida"
                                
                                v-model="filter.MrkDateExit" />
                        </v-col>
                    </v-row>
                </v-container>
            </template>
            <template v-slot="props">
                <v-container>
                    <v-row >
                        <v-col cols="2" class="s-col-form">
                            <s-toolbar-person noCamera label="Documento*" noEdit v-model="props.item.PrsDocumentNumber" @returnPerson="returnPerson($event, props.item)" />
                        </v-col>
                        <v-col cols="4" class="s-col-form">
                            <s-text readonly label="Persona" v-model="props.item.NtpFullName"></s-text>
                        </v-col>
                        <v-col cols="2" class="s-col-form">
                            <s-select-definition :def="1527" label="Estado Marcación" v-model="props.item.MrkTypeStatusMarking"></s-select-definition>
                        </v-col>
                        <v-col cols="3" class="s-col-form">
                            <s-date label="Marcacion *" type="datetime" v-model="props.item.MrkMarking"></s-date>
                        </v-col>
                        <!-- <v-col cols="2" class="s-col-form">
                            <s-date label="F. Marcacion" v-model="props.item.MrkDateMarking"></s-date>
                        </v-col> -->
                        <!-- <v-col cols="2" class="s-col-form">
                            <s-text  label="Minutos Refrigerio" v-model="props.item.MrkOfficialRefreshment"></s-text>
                        </v-col> -->
                        <!-- <v-col cols="3" class="s-col-form">
                            <s-date label="F. Registro" type="datetime" v-model="props.item.MrkRegistrationDate"></s-date>
                        </v-col> -->
                        <!-- <v-col cols="3" class="s-col-form">
                            <s-date label="Entrada Oficial" type="datetime" v-model="props.item.MrkOfficialEntry"></s-date>
                        </v-col>
                        <v-col cols="3" class="s-col-form">
                            <s-date label="Salida Oficial" type="datetime" v-model="props.item.MrkOfficialExit"></s-date>
                        </v-col> -->
                        <!-- <v-col cols="3" class="s-col-form">
                            <s-date label="F. Entrada" v-model="props.item.MrkDateEntry"></s-date>
                        </v-col>
                        <v-col cols="3" class="s-col-form">
                            <s-date label="F. Salida" v-model="props.item.MrkDateExit"></s-date>
                        </v-col> -->
                        <!-- <v-col cols="3" class="s-col-form">
                            <s-select-generic
                                label="Equipo Biometrico"
                                itemtext="BieTxtDisplay"
                                itemvalue="BieID"
                                id="BieID"
                                v-model="props.item.BieID"
                                :config="configSelectBiometric"
                            ></s-select-generic>
                        </v-col> -->
                        <!-- <v-col cols="3" class="s-col-form">
                            <s-text label="Observación" v-model="props.item.MrkObservation"></s-text>
                        </v-col> -->
                    </v-row>
                </v-container>
            </template>
        </s-crud>
    </v-card>
</template>
  
<script>
import _sMarking from "../../services/HumanResource/HmnMarkingService";
import _sBiometricEquipmentService from "@/services/HumanResource/BiometricEquipmentService";
import SText from "../../components/Utils/SText.vue";
import sToolbarPerson from "@/components/Utils/Person/sToolbarPerson";

export default {
  components: {
    SText,
    sToolbarPerson,
  },
  data() {
    return {
      filter: {},
      MrkOfficialRefreshment: 60,
      selected: [],
      config: {
        service: _sMarking,
        model: {
          MrkID: "ID",
          NtpID: "int",
          NtpFullName: "string",
          PrsDocumentNumber: "string",
          MrkDateEntry: "date",
          MrkDateExit: "date",
          MrkRegistrationDate: "datetime",
          MrkOfficialEntry: "datetime",
          MrkOfficialExit: "datetime",
          MrkDateMarking: "date",
          MrkMarking: "datetime",
        },
        headers: [
          { text: "ID", value: "MrkID" },
          { text: "Documento", value: "PrsDocumentNumber" },
          { text: "Trabajador", value: "NtpFullName" },
          { text: "Estado Marcacion", value: "StatusMarkingName" },
          { text: "Marcacion", value: "MrkMarking" },
          { text: "Fecha Registro", value: "MrkRegistrationDate" },
          { text: "Entrada Oficial", value: "MrkOfficialEntry" },
          { text: "Salida Oficial", value: "MrkOfficialExit" },
          { text: "Refrigerio (mm)", value: "MrkOfficialRefreshment" },
          { text: "Equipo", value: "BieEquipmentName" },
          { text: "Fecha Marcacion", value: "MrkDateMarking" },
          { text: "Fecha Entrada", value: "MrkDateEntry" },
          { text: "Fecha Salida", value: "MrkDateExit" },
          { text: "Observacion", value: "MrkObservation" },
        ],
      },
      configSelectBiometric: {
        url: _sBiometricEquipmentService.list(),
        params: {},
        title: "Biométrico",
      },
    };
  },
  methods: {
    returnPerson(value, item) {
      if(value == null) return;
      item.NtpFullName = value.NtpFullName;
      item.PrsDocumentNumber = value.PrsDocumentNumber;
      item.NtpID = value.NtpID;
      item.PrsID = value.PrsID;
      item.WkrID = value.WkrID;
    },
    save(item) {

      if (item.PrsDocumentNumber.length <= 0) {
        this.$fun.alert("El campo Documento es Obligatorio", "warning");
        return;
      }
      // if (item.MrkRegistrationDate == null || item.MrkRegistrationDate == "") {
      //   this.$fun.alert("El campo F. Registro es Obligatorio", "warning");
      //   return;
      // }
      // if (item.MrkOfficialEntry == null || item.MrkOfficialEntry == "") {
      //   this.$fun.alert("El campo F. Entrada es Obligatorio", "warning");
      //   return;
      // }
      // if (item.MrkOfficialExit == null || item.MrkOfficialExit == "") {
      //   this.$fun.alert("El campo F. Salida es Obligatorio", "warning");
      //   return;
      // }

      item.UsrID = this.$fun.getUserID();

      item.MrkStatus = 1;

      console.log(item);
      item.save();
    },
    rowSelected(item) {
      this.selected = item;
    },
    duplicate() {
      if (this.selected && this.selected.length > 0) {
        const mrkID = this.selected[0].MrkID;

        this.$fun
          .alert(
            `¿Está seguro de duplicar el registro seleccionado?`,
            "question"
          )
          .then((val) => {
            if (val.value) {
              _sMarking
                .duplicate({ MrkID: mrkID }, this.$fun.getUserID())
                .then((r) => {
                  this.$fun.alert(
                    "Registro duplicado correctamente",
                    "success"
                  );
                  this.$refs.ScrudMarking.refresh();
                })
                .catch((error) => {
                  this.$fun.alert(
                    "Ocurrió un error al duplicar el registro",
                    "error"
                  );
                });
            }
          });
      } else {
        this.$fun.alert(
          "Debe seleccionar al menos un registro para duplicar.",
          "warning"
        );
      }
    },
  },
};
</script>
  